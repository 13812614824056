<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" :title="$t('catalog.title')" :subtitle="$t('catalog.list.title')" :subtitle3rd="$t('catalog.registered.title')" />
    <div class="page-content container-fluid settings">
      <div class="row">
        <!-- Add product (sidebar) -->
        <b-sidebar id="sidebar-right" :title="$t('catalog.register.title')" right backdrop shadow>
          <div class="col desc-column">
            <p>{{$t('catalog.register.desc')}}</p>
          </div>
          <form class="add-prod-form col mb-0">
            <!-- Name -->
            <div class="input-group">
              <label for="">{{$t('catalog.register.name')}} <span class="material-symbols-outlined"> info </span></label>
              <input placeholder="" type="text" class="form-control" />
              <small>{{$t('catalog.register.max-limit-desc')}}</small>
            </div>

            <!-- URL -->
            <div class="input-group">
              <label for="">{{$t('catalog.register.url')}} <span class="material-symbols-outlined"> info </span></label>
              <input placeholder="https://" type="text" class="form-control" />
              <!-- <small>{{$t('catalog.register.max-limit-desc')}}</small> -->
            </div>

            <!-- Price -->
            <div class="input-group">
              <label for="">{{$tc('app.price', 1)}} <span class="material-symbols-outlined"> info </span></label>
              <input placeholder="R$" type="text" class="form-control" />
              <!-- <small>{{$t('catalog.register.max-limit-desc')}}</small> -->
            </div>

            <!-- Discount -->
            <!-- <div class="input-group">
              <label for="">{{$t('catalog.register.discount')}} <span class="material-symbols-outlined"> info </span></label>
              <input placeholder="%" type="text" class="form-control" />
            </div> -->

            <!-- Description -->
            <div class="input-group">
              <label for="">{{$t('catalog.description')}} <span class="material-symbols-outlined"> info </span></label>
              <textarea id="description-area" rows="4" class="form-control w-100" placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore"/>
              <small>{{$t('catalog.max-limit-desc')}}</small>
            </div>

            <!-- Image -->
            <label for="">{{$tc('app.image', 2)}} <span class="material-symbols-outlined"> info </span></label>
            <div class="import-wrapper">
              <div class="container-circular-progress-bar" v-if="fileLoaded">
                  <!-- <div class="circular-progress">
                      <span class="progress-value">0%</span>
                  </div> -->
                  <div id="preview">
                    <img v-if="url" :src="url" />
                  </div>
                  <!-- <span class="text-progress">HTML &amp; CSS</span> -->
              </div>
              <!-- <img class="csv-img" src="@/assets/import_csv.svg" alt="CSV Image" v-if="!fileLoaded" /> -->
              <span class="material-symbols-outlined icon-img" v-if="!fileLoaded"> image </span>
              <div class="w-100"></div>
              <p v-if="!fileLoaded" v-html="$t('catalog.click')"></p>
              <div v-if="!fileLoaded" class="w-100"></div>
              <small v-if="!fileLoaded" class="mx-auto">{{$t('catalog.max-img')}}</small>
              <div class="w-100"></div>
              <input
                id="validatedCustomIconLabel"
                type="file"
                accept=".png, .jpg"
                @change="processFile($event)"
                class="custom-file-input" required role="button" />
              <label
                class="custom-file-label-modal d-none"
                for="validatedCustomIconLabel">
                {{ file_name }}
              </label>
            </div>

            <!-- Gender -->
            <!-- <div class="input-group">
              <label for="">{{$t('generic-str.gender')}} <span class="material-symbols-outlined"> info </span></label>
              <select name="genre" id="genre-select" class="form-control">
                <option value="feminine">{{ $t('generic-str.feminine') }}</option>
                <option value="masculine">{{ $t('generic-str.masculine') }}</option>
              </select>
              <small>{{$t('catalog.max-limit-desc')}}</small>
            </div> -->

            <!-- Product specs -->
            <div class="input-group">
              <!-- <label for="">{{$t('generic-str.gender')}} <span class="material-symbols-outlined"> info </span></label> -->
              <select name="spec" id="spec-select" class="form-control" placeholder="">
                <option value="">{{$t('catalog.product-spec')}}</option>
                <option value="first">Opt 1</option>
                <option value="second">Opt 2</option>
              </select>
              <small class="w-100">{{$t('catalog.max-limit-desc')}}</small>
            </div>

            <!-- Brand -->
            <!-- <div class="input-group">
              <label for="">{{$t('catalog.brand')}} <span class="material-symbols-outlined"> info </span></label>
              <input placeholder="Ex.: Adidas" type="text" class="form-control" />
            </div> -->

            <!-- Model -->
            <!-- <div class="input-group">
              <label for="">{{$t('catalog.model')}} <span class="material-symbols-outlined"> info </span></label>
              <input placeholder="Ex.: 43" type="text" class="form-control" />
            </div> -->

            <!-- Reference -->
            <div class="input-group">
              <label for="">{{$t('catalog.reference')}} <span class="material-symbols-outlined"> info </span></label>
              <input placeholder="Ex.: 123456789" type="text" class="form-control" />
            </div>

            <!-- Bar code -->
            <!-- <div class="input-group">
              <label for="">{{$t('catalog.bar-code')}} <span class="material-symbols-outlined"> info </span></label>
              <input placeholder="Ex.: 98789878945645123" type="text" class="form-control" />
            </div> -->

            <!-- Included items -->
            <!-- <div class="input-group">
              <label for="">{{$t('catalog.inc-items')}} <span class="material-symbols-outlined"> info </span></label>
              <input :placeholder="`Ex.: ${$t('catalog.inc-items-ex')}`" type="text" class="form-control" />
            </div> -->

            <!-- Additional message -->
            <!-- <div class="input-group">
              <label for="">{{$t('catalog.additional-msg')}} <span class="material-symbols-outlined"> info </span></label>
              <input :placeholder="`Ex.: ${$t('catalog.additional-msg-ex')}`" type="text" class="form-control" />
            </div> -->

            <!-- Warranty -->
            <!-- <div class="input-group">
              <label for="">{{$t('catalog.warranty.title')}} <span class="material-symbols-outlined"> info </span></label>
              <select name="spec" id="spec-select" class="form-control" placeholder="">
                <option value="">{{$t('catalog.warranty.none')}}</option>
                <option value="first">Opt 1</option>
                <option value="second">Opt 2</option>
              </select>
            </div> -->

            <!-- Weight -->
            <!-- <div class="input-group">
              <label for="">{{$t('catalog.weight')}} <span class="material-symbols-outlined"> info </span></label>
              <input placeholder="Ex.: 1kg" type="text" class="form-control" />
            </div> -->
            <button class="btn btn-outline-primary">{{$t('generic-str.cancel')}}</button>
            <button class="btn btn-primary ml-auto">{{$t('generic-str.save')}}</button>
          </form>
          <!-- <div class="px-3 py-2">
            <p>
              Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
              in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
            </p>
            <b-img src="https://picsum.photos/500/500/?image=54" fluid thumbnail></b-img>
          </div> -->
        </b-sidebar>
        <!--/Add product (sidebar) -->
        <!-- Edit product (sidebar) -->
        <b-sidebar id="sidebar-right-edit" :title="$t('catalog.edit.title')" right backdrop shadow>
          <div class="col desc-column">
            <p>{{$t('catalog.register.desc')}}</p>
          </div>
          <form class="add-prod-form col mb-0">
            <!-- Name -->
            <div class="input-group">
              <label for="">{{$t('catalog.register.name')}} <span class="material-symbols-outlined"> info </span></label>
              <input placeholder="" type="text" class="form-control" />
              <small>{{$t('catalog.register.max-limit-desc')}}</small>
            </div>

            <!-- URL -->
            <div class="input-group">
              <label for="">{{$t('catalog.register.url')}} <span class="material-symbols-outlined"> info </span></label>
              <input placeholder="https://" type="text" class="form-control" />
              <small>{{$t('catalog.register.max-limit-desc')}}</small>
            </div>

            <!-- Price -->
            <div class="input-group">
              <label for="">{{$tc('app.price', 1)}} <span class="material-symbols-outlined"> info </span></label>
              <input placeholder="R$" type="text" class="form-control" />
              <small>{{$t('catalog.register.max-limit-desc')}}</small>
            </div>

            <!-- Discount -->
            <div class="input-group">
              <label for="">{{$t('catalog.register.discount')}} <span class="material-symbols-outlined"> info </span></label>
              <input placeholder="%" type="text" class="form-control" />
              <small>{{$t('catalog.register.max-limit-desc')}}</small>
            </div>

            <!-- Image -->
            <label for="">{{$tc('app.image', 2)}} <span class="material-symbols-outlined"> info </span></label>
            <div class="import-wrapper">
              <div class="container-circular-progress-bar" v-if="fileLoaded">
                  <!-- <div class="circular-progress">
                      <span class="progress-value">0%</span>
                  </div> -->
                  <div id="preview">
                    <img v-if="url" :src="url" />
                  </div>
                  <!-- <span class="text-progress">HTML &amp; CSS</span> -->
              </div>
              <!-- <img class="csv-img" src="@/assets/import_csv.svg" alt="CSV Image" v-if="!fileLoaded" /> -->
              <span class="material-symbols-outlined icon-img" v-if="!fileLoaded"> image </span>
              <div class="w-100"></div>
              <p v-if="!fileLoaded" v-html="$t('catalog.click')"></p>
              <div v-if="!fileLoaded" class="w-100"></div>
              <small v-if="!fileLoaded" class="mx-auto">{{$t('catalog.max-img')}}</small>
              <div class="w-100"></div>
              <input
                id="validatedCustomIconLabel"
                type="file"
                accept=".png, .jpg"
                @change="processFile($event)"
                class="custom-file-input" required role="button" />
              <label
                class="custom-file-label-modal d-none"
                for="validatedCustomIconLabel">
                {{ file_name }}
              </label>
            </div>

            <!-- Gender -->
            <div class="input-group">
              <label for="">{{$t('generic-str.gender')}}</label>
              <select name="genre" id="genre-select" class="form-control">
                <option value="feminine">{{ $t('generic-str.feminine') }}</option>
                <option value="masculine">{{ $t('generic-str.masculine') }}</option>
              </select>
              <small>{{$t('catalog.max-limit-desc')}}</small>
            </div>

            <!-- Product specs -->
            <div class="input-group">
              <!-- <label for="">{{$t('generic-str.gender')}}</label> -->
              <select name="spec" id="spec-select" class="form-control" placeholder="">
                <option value="">{{$t('catalog.product-spec')}}</option>
                <option value="first">Opt 1</option>
                <option value="second">Opt 2</option>
              </select>
              <small>{{$t('catalog.max-limit-desc')}}</small>
            </div>

            <!-- Brand -->
            <div class="input-group">
              <label for="">{{$t('catalog.brand')}}</label>
              <input placeholder="Ex.: Adidas" type="text" class="form-control" />
            </div>

            <!-- Model -->
            <div class="input-group">
              <label for="">{{$t('catalog.model')}}</label>
              <input placeholder="Ex.: 43" type="text" class="form-control" />
            </div>

            <!-- Reference -->
            <div class="input-group">
              <label for="">{{$t('catalog.reference')}}</label>
              <input placeholder="Ex.: 123456789" type="text" class="form-control" />
            </div>

            <!-- Bar code -->
            <div class="input-group">
              <label for="">{{$t('catalog.bar-code')}}</label>
              <input placeholder="Ex.: 98789878945645123" type="text" class="form-control" />
            </div>

            <!-- Included items -->
            <div class="input-group">
              <label for="">{{$t('catalog.inc-items')}}</label>
              <input :placeholder="`Ex.: ${$t('catalog.inc-items-ex')}`" type="text" class="form-control" />
            </div>

            <!-- Additional message -->
            <div class="input-group">
              <label for="">{{$t('catalog.additional-msg')}}</label>
              <input :placeholder="`Ex.: ${$t('catalog.additional-msg-ex')}`" type="text" class="form-control" />
            </div>

            <!-- Warranty -->
            <div class="input-group">
              <label for="">{{$t('catalog.warranty.title')}}</label>
              <select name="spec" id="spec-select" class="form-control" placeholder="">
                <option value="">{{$t('catalog.warranty.none')}}</option>
                <option value="first">Opt 1</option>
                <option value="second">Opt 2</option>
              </select>
            </div>

            <!-- Weight -->
            <div class="input-group">
              <label for="">{{$t('catalog.weight')}}</label>
              <input placeholder="Ex.: 1kg" type="text" class="form-control" />
            </div>
            <button class="btn btn-outline-primary">{{$t('generic-str.cancel')}}</button>
            <button class="btn btn-primary ml-auto">{{$t('generic-str.save')}}</button>
          </form>
          <!-- <div class="px-3 py-2">
            <p>
              Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
              in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
            </p>
            <b-img src="https://picsum.photos/500/500/?image=54" fluid thumbnail></b-img>
          </div> -->
        </b-sidebar>
        <!--/Edit product (sidebar) -->
        <!-- Search column -->
        <div class="col-6 mx-auto mb-5">
          <div class="form-wrapper">
            <form @submit.prevent="search" class="form-group col px-0 mb-0">
              <div class="input-group">
                <input
                  placeholder=""
                  type="text"
                  class="form-control"
                  :v-model="searchQuery"
                  ref="searchInput"
                />
                <div class="input-group-prepend">
                  <button @click="search" type="button" class="btn icon_btn m-0">
                    <span class="material-symbols-outlined"> search </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!--/Search column -->
      </div>
      <!-- My Catalogs -->
      <div class="container container-catalogs">
        <div class="row mb-4">
          <div class="col-12 d-flex">
            <span class="material-symbols-outlined mr-2"> trolley </span><span class="h5">{{$t('catalog.registered.title')}}</span>
            <ul class="list-unstyled options-cat">
              <li><span class="material-symbols-outlined"> filter_list </span></li>
              <li class="options-item" :class="[viewType === 'grid' ? 'active' : '']"><span class="material-symbols-outlined" role="button" @click="changeType('grid')"> grid_view </span></li>
              <li class="options-item" :class="[viewType === 'list' ? 'active' : '']"><span class="material-symbols-outlined" role="button" @click="changeType('list')"> list </span></li>
            </ul>
          </div>
        </div>
        <!-- Title -->
        <div class="row mb-4" v-if="viewType === 'list'">
          <div class="col-12 col-sm"><span class="title-list">{{$t('catalog.registered.cod')}}</span></div>
          <div class="col-12 col-sm"><span class="title-list">{{$t('catalog.registered.var')}}</span></div>
          <div class="col-12 col-sm"><span class="title-list">{{$tc('app.value', 1)}}</span></div>
          <div class="col-12 col-sm-2"><span class="title-list">Status</span></div>
          <div class="col-12 col-sm-2"><button v-b-toggle.sidebar-right class="btn btn-outline-primary btn-add-list mx-sm-auto">{{$t('catalog.registered.add')}} <span class="material-symbols-outlined"> add </span></button></div>
          <hr class="purple">
        </div>
        <!--/Title -->
        <!-- Content -->
        <div class="row mb-4" :class="[viewType === 'list' ? 'task-list' : 'task-grid']">
          <div v-b-toggle.sidebar-right role="button" class="card col-12 col-sm add-prod-card" v-if="viewType === 'grid'">
            <img src="@/assets/add-dashed-circle.gif" alt="Add" width="167">
            <p class="text-yup">{{$t('catalog.registered.add')}}</p>
          </div>
          <template v-for="index in 10">
            <div class="" :class="[viewType === 'grid' ? 'card col-12 col-sm' : 'content-row']" :key="index">
              <div class="col-12 col-sm img-wrapper">
                <img class="img-fluid prod-img" src="@/assets/shoes.jpeg" alt="">
                <div class="info-wrapper">
                  <a href="#" class="text-yup">Título produto <span class="material-symbols-outlined text-yup"> call_made </span></a>
                  <span class="">#3565 <span class="material-symbols-outlined"> content_copy </span></span>
                </div>
              </div>
              <div class="col-12 col-sm var-col tags-wrapper">
                <span class="var">44</span>
                <span class="var">40</span>
                <span class="var">38</span>
                <span class="var">36</span>
              </div>
              <div class="col-12 col-sm value-col value-wrapper">
                <span class="material-symbols-outlined mr-1"> monetization_on </span><span>1000,00</span>
              </div>
              <div class="col-12 status-wrapper" :class="[viewType === 'list' ? 'col-sm-2' : '']">
                <span class="badge badge-optional">● Ativo</span>
              </div>
              <div class="col-12 options-wrapper" :class="[viewType === 'list' ? 'col-sm-2' : '']">
                <ul class="list-unstyled options-content">
                  <li v-if="viewType === 'list'"><span v-b-toggle.sidebar-right-edit class="material-symbols-outlined"> edit </span></li>
                  <li v-if="viewType === 'list'"><span class="material-symbols-outlined"> content_copy </span></li>
                  <li v-if="viewType === 'list'"><span class="material-symbols-outlined"> control_point_duplicate </span></li>
                  <li><b-dropdown right variant="link p-0" toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                      <span class="material-symbols-outlined"> more_horiz </span><span class="sr-only">Search</span>
                    </template>
                    <b-dd-item href="#"><span class="material-symbols-outlined"> edit </span>Editar</b-dd-item>
                    <b-dd-item href="#"><span class="material-symbols-outlined"> control_point_duplicate </span>Duplicar</b-dd-item>
                    <b-dd-item href="#"><span class="material-symbols-outlined"> archive </span>Arquivar</b-dd-item>
                    <b-dd-item href="#"><span class="material-symbols-outlined"> info </span>Mais detalhes</b-dd-item>
                  </b-dropdown></li>
                </ul>
              </div>
            </div>
          </template>
        </div>
        <!--/Content -->
        <!-- Pagination -->
        <!-- static pagination (only for preview purpose) -->
        <div id="bs4-table_paginate" class="dataTables_paginate paging_simple_numbers">
            <ul class="pagination">
                <li id="bs4-table_previous" class="paginate_button page-item previous disabled">
                    <a href="javascript:void(0)" aria-controls="bs4-table" data-dt-idx="0" tabindex="0" class="page-link">
                        <span class="material-icons-outlined"> arrow_back_ios </span>
                    </a>
                </li>
                <li class="paginate_button page-item active">
                    <a href="javascript:void(0)" aria-controls="bs4-table" data-dt-idx="1" tabindex="0" class="page-link">1</a>
                </li>
                <li class="paginate_button page-item">
                    <a href="javascript:void(0)" aria-controls="bs4-table" data-dt-idx="2" tabindex="0" class="page-link">2</a>
                </li>
                <li id="bs4-table_next" class="paginate_button page-item next">
                    <a href="javascript:void(0)" aria-controls="bs4-table" data-dt-idx="7" tabindex="0" class="page-link">
                        <span class="material-icons-outlined"> arrow_forward_ios </span>
                    </a>
                </li>
            </ul>
        </div>
        <!-- <pagination :lastPage="pages" @change="fetch" /> -->
        <!--/Pagination -->
      </div>
      <!-- My Catalogs -->
    </div>
  </div>
</template>

<script>
import 'bootstrap-vue/dist/bootstrap-vue.css';
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
// import Pagination from '@/components/rebranding/Pagination.vue';
// import HiddenInput from '@/components/HiddenInput.vue';
// import WhatsService from '@/services/whatsapp.service';
// import Darkmode from '@/components/rebranding/SetTheme.vue';

export default {
  name: 'Settings',
  components: {
    PageHeader,
    // Pagination
    // HiddenInput,
    // Darkmode,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/catalog',
        2: '/catalog/list',
        3: '/catalog/list/registered',
      },
      fetched: false,
      searchQuery: null,
      url: null,
      fileLoaded: false,
      fileImport: {},
      file_name: this.$t('import-contacts-component.modal.select'),
      // pages: 1,
      viewType: 'list',
    };
  },
  methods: {
    search() {
      this.fetched = false;
      console.info('Search function');
      /* const word = this.$refs.searchInput.value;
      // console.log('Query: ', word);
      let result = null;
      SmsService.getBlacklists(this.form).then(
        (response) => {
          this.fetched = true;
          this.blacklists = response.data;
          // console.log('Templates: ', this.blacklists);
          result = this.blacklists.filter((item) => word.toLowerCase().split(' ').every((v) => item.mobile_number.toLowerCase().includes(v)));
          // console.log('Return result: ', result);
          this.blacklists = result;
        },
        (error) => {
          this.content = error;
        },
      ); */
    },
    /* fetch(page = 1) {
      this.form.page = page;
      this.fetched = false;
      MailService.getBlacklists(this.form).then(
        (response) => {
          this.fetched = true;
          this.blacklists = response.data;
          console.log('MailService.getBlacklists', this.blacklists, response.last_page);
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    }, */
    processFile(event) {
      event.preventDefault();
      this.fileImport = event.target.files[0];
      this.url = URL.createObjectURL(this.fileImport);
      console.info('Selected file: ', this.fileImport);
      this.fileLoaded = true;
      this.file_name = `${this.$t('import-contacts-component.modal.selected')}: ${this.fileImport.name}`;
    },
    changeType(type) {
      this.viewType = type;
    },
  },
};
</script>
<style scoped lang="scss">
.page-header {
  padding-bottom: 2rem;
}
/* Search form style */
.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }
  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }
  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }
  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .rebranding .form-group .form-control,
.rebranding .form-group .form-control {
  border-right: unset !important;
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}
.form-wrapper {
  flex: 1 0 20%;
  .form-group {
    height: 55px;
  }
  .form-control,
  .input-group-prepend,
  .input-group,
  .icon_btn {
    height: 100%;
    span {
      color: var(--gray-font-color-5);
      font-size: 38px;
    }
  }
}
@media (min-width: 1660px){
  .form-wrapper {
    min-width: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex-basis: 0;
    flex-grow: 1;
  }
}
@media (max-width: 767.98px) {
  .form-wrapper {
    flex: 1 0 40%;
  }
}
/*/Search Form style */
.container-add, .container-catalogs {
  max-width: 1740px;
}

/* List */
.title-list {
  color: var(--gray-font-color-5);
  font-weight: 400;
  display: block;
  padding: 8px 20px;
}
.options-cat {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;
  gap: 10px;
  li {
    color: var(--gray-font-color-5);
  }
  .options-item.active {
    span {
      color: var(--clr-yup-purple);
    }
  }
}
.btn.btn-add-list {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
  border: unset !important;
  font-size: 1rem;

  &:hover, &:focus {
    color: var(--clr-yup-purple-hover) !important;
    span {
      border-color: var(--clr-yup-purple-hover);
    }
  }

  span {
    margin-left: 5px;
    border: 1px solid var(--clr-yup-purple);
    border-radius: 50%;
    font-size: 18px;
    font-weight: 400;
  }
}
hr.purple {
  width: 98%;
  border-top: 5px solid #6B23DC3D;
  border-radius: 75px;
}
.content-row {
  display: flex;
  background-color: var(--background-2);
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding-left: 0;
  padding-right: 0;
  >div {
    display: flex;
    align-items: center;
    min-height: 72px;
  }
  @media (min-width: 768px) {
    div:last-child {
      justify-content: center;
    }
  }
}
.options-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.75rem;
  margin-bottom: unset;
  li, .dropdown-toggle span {
    color: var(--gray-font-color-5);
  }
  .dropdown-toggle:hover, .dropdown-toggle:focus {
    color: var(--gray-font-color);
  }
}
#close-dialog {
  position: absolute;
  top: 6px;
  right: 10px;
  cursor: pointer;
  transform: scale(1.25);
  padding: 10px;
  background: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
#close-dialog::after {
  content: '\e5cd';
  font-family: 'Material Symbols Outlined';
  color: #FFF;
  transform: scale(1.6);
}
.tag-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: unset;
  li {
    color: var(--gray-font-color-5);
  }
}
::v-deep {
  .dropdown .dropdown-menu.dropdown-menu-right {
    right: auto !important;
    left: 0 !important;
  }
  .dropdown-item {
    display: flex !important;
    align-items: center !important;
    gap: 10px;
    color: var(--gray-font-color-5);
  }
  .pagination {
    justify-content: end;
  }
}
.prod-img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  object-position: center;
}
.info-wrapper {
  display: flex;
  flex-direction: column;

  a:not(.material-symbols-outlined), span:not(.material-symbols-outlined) {
    font-weight: 400;
    padding: 8px 20px;
  }

  a {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-bottom: unset !important;
  }
  span {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-top: unset !important;
    color: var(--gray-font-color-5);
  }
}
.value-col {
  color: var(--gray-font-color-5);
}
.var-col {
  .var {
    padding: 4px 8px;
    background-color: #807C8D14;
    color: var(--gray-font-color-5);
    border-radius: 8px;
    margin-right: 10px;
  }
}
/* Sidebar add prod */
::v-deep #sidebar-right, ::v-deep #sidebar-right-edit {
  background-color: var(--background-2) !important;
  width: 525px;
  small {
    color: var(--gray-font-color);
  }

  .b-sidebar-header {
    padding-left: 2rem;
    padding-right: 2rem;
    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }
  .b-sidebar-body {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.5rem;
  }
  header {
    >strong {
      order: -1;
    }
  }
  .desc-column {
    p {
      color: var(--gray-font-color);
    }
  }
  textarea, select, .form-control {
    border-radius: 8px;
  }
}
.add-prod-form {
  display: flex;
  flex-wrap: wrap;
  .input-group, .import-wrapper {
    margin-bottom: 2rem;
    input {
      width: 100%;
    }
  }

  label {
    display: flex;
    gap: 5px;
    width: 100%;
    margin-bottom: .725rem;
    font-size: 1rem !important;

    span {
      color: var(--gray-font-color-3);
    }
  }
}
/* Import img */
.import-wrapper {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  border: 2px dashed var(--clr-yup-purple);
  border-radius: 12px;
  /* margin: 0 10%; */
  padding: 1rem 0;
  width: 100%;
  @media (max-width: 767.98px) {
    margin: 0;
  }
  p {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
    position: relative;
    z-index: 2;
  }
  img.csv-img, .custom-file-label-modal {
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  .icon-img {
    margin: 0 auto;
    margin-bottom: 1rem;
    color: var(--gray-font-color);
  }
  .custom-file-label-modal {
    background-color: #FFF;
    color: var(--clr-yup-purple);
    padding: .75rem;
    border: 1px solid var(--clr-yup-purple);
    border-radius: 4px;
    cursor: pointer;
    font-weight: 400;
    transition: .5s;
    position: relative;
    z-index: 2;
    &:hover, &:focus {
      border-color: var(--clr-yup-purple-hover) !important;
      background-color: var(--clr-yup-purple-hover) !important;
      color: #fff !important;
    }
  }
  #preview {
  display: flex;
  justify-content: center;
  align-items: center;
  }

  #preview img {
    /* max-width: 100%; */
    max-width: 60%;
    max-height: 500px;
  }
}
.custom-file-input {
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 1;
  cursor: default;
}
/*/Sidebar add prod */

/* List type */
::v-deep .task-list {
  .content-row {
    width: 100%;
  }
}
::v-deep .task-grid {
  flex-wrap: wrap;
  // gap: 2.5rem;
  @media (min-width: 1660px) {
    // gap: 8rem;
  }
  .card {
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 0 16.6875rem;
    max-width: 16.6875rem;
    height: fit-content;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    margin-right: 2.5rem;
    @media (min-width: 1660px) {
      margin-right: 8rem;
    }
    .status-wrapper{
      order: -1;
    }
    .options-wrapper {
      order: 0;
      ul {
        margin-left: auto;
      }
      button {
        height: auto;
      }
    }
    .status-wrapper, .options-wrapper {
      display: flex;
      align-items: center;
      flex: 1 0 50%;
    }
    .img-wrapper {
      flex: 1 0 100%;
      max-width: 100%;
      padding: 0;
      order: 2;
      margin-top: 1rem;
      img {
        width: 100%;
        height: fit-content;
        max-height: 10.5625rem;
        object-fit: cover;
        border-radius: 8px;
      }
    }
    .info-wrapper *, .value-wrapper, .tags-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
    .info-wrapper {
      span:not(span.material-symbols-outlined) {
        padding-bottom: 1.5rem;
      }
    }
    .value-wrapper {
      order: 3;
      display: flex;
      align-items: center;
      flex: 1 0 100%;
      margin-bottom: 1.5rem;
    }
    .tags-wrapper {
      order: 4;
      flex: 1 0 100%;
      max-width: 100%;
    }
  }
  .add-prod-card {
    min-width: 267px;
    min-height: 270px;
    padding-bottom: 1rem;
    img {
      width: 100%;
      height: 100%;
      margin-bottom: 1rem;
    }
    p {
      width: 100%;
      font-weight: bold;
      font-size: 1.5rem;
      margin-bottom: 0;
      text-align: center;
    }
  }
}
</style>
